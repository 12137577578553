import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react';

const lineAnimation = keyframes`
    0% {
      top: -5%;
    }
    30% {
     // height: 15vh;
    }
    80% {
      height: 10vh;
    }
    100% {
      top: 105%;
    }
`;


export const AnimatedLine = styled.div`
    position: fixed;
    width: 1px;
    height: 115.47%;
    left:20%;
    top: 0;
    transform: rotate(-30deg);
    transform-origin: top left;
    z-index: -1;
    ::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 3vh;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #4c4878 75%, #6632d4 100%);
      animation: ${lineAnimation} 7s 0s infinite;
       animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53)
    }   
`

export const AnimatedLine2 = styled(AnimatedLine)`
    height: 70vw;
    left: 60%; 
     top: -10%;
    transform: rotate(56deg);
    ::after{
      animation: ${lineAnimation} 6.5s 1.2s infinite;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #485d78 75%, #3250d4 100%);
    }
`

export const AnimatedLine3 = styled(AnimatedLine)`
    position: absolute;
    height: 110vw;
    left: -5%;
    top: 152px;
    transform: rotate(-90deg);
    ::after{
      animation: ${lineAnimation} 6s 0.4s infinite;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #485d78 75%, #3250d4 100%);
    }
        @media (max-width: 768px){
    top:142px;
  }
       @media (max-width: 620px) {
        top:120px;
    }
        @media (max-width: 480px) {
        top:106px;
        }    
`

export const AnimatedLine4 = styled(AnimatedLine)`
    height: 90%;
    left: 105%; 
    top: 20%;
    transform: rotate(27deg);
    ::after{
      animation: ${lineAnimation} 7.5s 2s infinite;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #485d78 75%, #6632d4 100%);
    }
`

