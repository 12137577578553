
import './App.css';
import { AnimatedSpan, AppWrapper, BottomSectionText, BottomSectionTitle, FeatureList, FeatureListItem, FeatureListItemIconWrapper, FeatureListItemText, SeparatorLine, SubscribeBox, SubscribeButton, SubscribeButtonWrapper, SubscribeInput, SubscribeInputWrapper } from './App.styles';
import { AnimatedLine, AnimatedLine2, AnimatedLine3, AnimatedLine4 } from './LinesBg.styles';
// import './lines.css';
import { useEffect, useState } from 'react';



function App() {
  const titleTexts = ["Web site", "Web app", "Shop", "Community"];
  const [titleTextIndex, setTitleTextIndex] = useState(0);
  const [isAnimateText, setIsAnimateText] = useState(0);

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const textChangeInterval = setInterval(() => {
      setIsAnimateText(1);
      setTimeout(() => {
        setTitleTextIndex(prevIndex => (prevIndex + 1) % titleTexts.length);
        setIsAnimateText(0);
      }, 250); 
    }, 3000);

    // const textAnimateInterval = setInterval(() => {
    //   setTitleTextIndex(prevIndex => (prevIndex + 1) % titleTexts.length);
    // }, 300);

    return () => {
      clearInterval(textChangeInterval);
      // clearInterval(textAnimateInterval);
    }
  }, [titleTexts.length]);

  const submitForm = (e) => {
    var form = document.querySelector('.pageclip-form')
    window.Pageclip && window.Pageclip.form(form, {
      onSubmit: (event)=> { console.log("Success"); setIsSubscribed(true) },
      onResponse: (error, response) => {  console.log("Error")},
      successTemplate: '<span>Thank you!</span>'
    })
  }

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   if (queryParams.has('subscribed')) {
  //     setIsSubscribed(true);
  //   }
  // }, []);

  return (
      <AppWrapper className="AppWrapper">
          <AnimatedLine/>
          <AnimatedLine2/>
          <AnimatedLine3/>
          <AnimatedLine4/>
          <h1>SMART-WEB.AI</h1>
          <div className='top-title'>The easiest and quickest tool</div>
          <h2>To make your <AnimatedSpan isAnimateOut={isAnimateText === 1}>{titleTexts[titleTextIndex]}</AnimatedSpan></h2>
          <div className='wayRow'>
            <span className='small'>way</span>
            <span className='small2'>way</span>
            <span className='medium'>way</span>
            <span className='big'>way</span>
            <span className='big2'>way</span>
            <span className='text'>Smarter!</span>
          </div>
          <SeparatorLine/>
          <BottomSectionTitle>How It Works?</BottomSectionTitle>
          <BottomSectionText>Connect our agent to your website in a few clicks. That's it.</BottomSectionText>
          <BottomSectionTitle>What you'll get?</BottomSectionTitle>
          <FeatureList>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
                <span className="material-symbols-outlined">smart_toy</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
                AI Agent to help users with processes or any questions
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">forum</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
                Personalized and dynamic onboarding questionnaire
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">verified</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
              Promote products and pages
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">network_node</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
              Notify users about new features and content
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">manage_accounts</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
              Personalized user settings
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">psychology</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
              User behavior analytics and insights
              </FeatureListItemText>
            </FeatureListItem>
            <FeatureListItem>
              <FeatureListItemIconWrapper>
              <span className="material-symbols-outlined">favorite</span>
              </FeatureListItemIconWrapper>
              <FeatureListItemText>
              And a lot more...
              </FeatureListItemText>
            </FeatureListItem>
            
          </FeatureList>
         

          {isSubscribed ? <BottomSectionTitle paddingBottom={80}>Thank you for subscribing!</BottomSectionTitle> : <>
            <BottomSectionTitle>Sounds great, keep me updated!</BottomSectionTitle>
            <form action="https://send.pageclip.co/FGgPMFmc9Y4uSiPTE0RVZdJ7HCNNzhhz"
           className="pageclip-form" method="post" >
          <SubscribeBox>
            <SubscribeInputWrapper>
            <SubscribeInput type="email" required name="email" placeholder="Enter your email"/>
            </SubscribeInputWrapper>
            <SubscribeButtonWrapper>
            {/* <SubscribeButton type="submit" > Subscribe</SubscribeButton> */}
            <SubscribeButton onClick={submitForm}> Subscribe</SubscribeButton>
            </SubscribeButtonWrapper>
          </SubscribeBox>
          </form>
          </> }
          
          
      </AppWrapper>
      

    
  );
}



export default App;
