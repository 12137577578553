import styled from '@emotion/styled/macro';

export const AppWrapper = styled.div`
      align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 16px;
  position: relative;
  z-index: 2;

  h1{
  padding:40px 20px 24px;
  margin:0px;
  
  font-size: 72px;
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  color:transparent;
    background: linear-gradient(239deg, #e334b0 34%, #32d2f1);
    background-clip: text;
  @media (max-width: 768px){
    font-size: 62px;
  }
    @media (max-width: 620px) {
        font-size: 46px;
    }
        @media (max-width: 480px) {
        font-size: 36px;
        }
  }
  h2{
    padding:8px 20px;
    color:#fff;
    font-family:"Poppins", sans-serif;
    font-size: 52px;
    margin:0px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 768px){
       flex-direction: column;
    }
    @media (max-width: 480px) {
        line-height: 52px
    }   
  }
`

export const AnimatedSpan = styled.span`
    margin:0px;
    padding:0px 16px;  
    font-family: "Open Sans", sans-serif;
    font-size: 52px;
    color:transparent;
    background: linear-gradient(15deg, #e39634 34%, #b732f1);
    background-clip: text;
    height: 70px;
    overflow-y: hidden;
    transform: translateY(${props=>props.isAnimateOut ? '-40' : '0'}px);
    opacity: ${props=>props.isAnimateOut ? '0' : '1'};
    transition: all 0.25s;
    @media (max-width: 480px) {
        margin-top:12px;
    }   
`


export const SeparatorLine = styled.div`
    width:calc(100% + 32px);
    height: 1px;
    min-height: 1px;
    background: linear-gradient(90deg, #e334b0 34%, #32d2f1);
    margin: 56px 0px;
    opacity: 0.3;
`

export const BottomSectionTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 40px;
  text-align: center;
  padding:0 8px;
  ${props=>props.paddingBottom ? `padding-bottom: ${props.paddingBottom}px;` : ''}
   color:transparent;
    background: linear-gradient(-90deg, #2d7da8 34%, #b84fee);
    background-clip: text;
    @media (max-width: 480px) {
       line-height: 50px;
    }  
`;

export const BottomSectionText = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 24px;
  color:#fff;
  text-align: center;
  margin-bottom: 32px;
  padding:0 32px;
`;

export const FeatureList = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 24px;
  color:#fff;
  display: flex;
  flex-direction: column;
  padding:8px 32px 24px;
  gap:16px;
  
`;

export const FeatureListItem = styled.div`
  display: flex;
  gap:16px;
  align-items: flex-start;
`;

export const FeatureListItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2px;
     color:transparent;
   background: linear-gradient(15deg, #b6e8ff 34%, #f4ff02);
    background-clip: text;
  span{
    font-size: 30px;
    
  }
`;

export const FeatureListItemText = styled.div`
  line-height: 34px;
`;



export const SubscribeBox = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 80px;
  width:100%;
  justify-content: center;
  gap:16px;
  @media (max-width: 480px) {
       flex-direction: column;
  }
  width: 700px;
  max-width: 100vw;
`;

export const SubscribeInputWrapper = styled.div`
  background: linear-gradient(15deg, #b6e8ff 34%, #f4ff02);
  display: flex;
  justify-content: center;
  align-items: center;
  width:60%;
  height:48px;
    @media (max-width: 768px){
    width:50%;
    }
    @media (max-width: 480px) {
       width:70%;
    }  
       border-radius: 20px;
`;

export const SubscribeInput = styled.input`
    outline: none;
    border: none;
    background-color: #0d0414;
    border-radius: 18px;
    height:44px;
    color:#fff;
    padding:8px 16px;
    font-size: 18px;
    width:calc(100% - 4px);
  `;


  export const SubscribeButtonWrapper = styled.div`
  background: linear-gradient(340deg, #b6e8ff 34%, #f4ff02);
  display: flex;
  justify-content: center;
  align-items: center;
  height:48px;
  border-radius: 20px;
  padding:0px 2px;
`;
export const SubscribeButton = styled.button`
    height:44px;
    border-radius: 18px;
    padding:0 16px;
    outline: none;
    border:none;// 1px solid #d1d1d1;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    cursor: pointer;
    background-color: #0d0414;
    border-radius: 18px;
    
    color:#7bdcff;
`;